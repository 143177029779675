import React, { PropsWithChildren, Suspense, useEffect } from 'react';
import { OpenFeature } from '@openfeature/web-sdk';
import { Loader } from 'src/components/ui/Loader';
import useFeature, { FeatureFlag } from 'src/hooks/useFeature';
import { useGetDeviceOverview } from 'src/queries/device/useGetDeviceOverview';
import { useGetEmergencyAlerts } from 'src/queries/emergencyAlerts/useGetEmergencyAlerts';
import { useGetCalendarConnections } from 'src/queries/organization/useGetCalendarConnections';
import { useGetOrganizationOverview } from 'src/queries/organization/useGetOrganizationOverview';
import { useGetSubscriptionInfoSuspense } from 'src/queries/subscriptions/useGetSubscriptionInfoSuspense';
import { useGetUserSuspense } from 'src/queries/user/useGetUserSuspense';
import { useAuthStore } from 'src/store/authStore';
import { googleAnalytics } from 'src/utils/googleAnalytics';

/**
 * Suspends the application while the initial data loads
 *
 */
export const BootstrapData = ({ children }: PropsWithChildren<{}>): React.ReactElement => {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);

  return (
    <Suspense fallback={<Loader />}>
      {children}
      {isAuthenticated && <LoadInitialData />}
    </Suspense>
  );
};

const LoadInitialData = ({ children }: PropsWithChildren<{}>): React.ReactElement => {
  const { data: user } = useGetUserSuspense();
  const orgData = useGetOrganizationOverview();
  const isEmergencyAlertsEnabled = useFeature(FeatureFlag.EMERGENCY_ALERTS);
  useGetDeviceOverview();
  useGetSubscriptionInfoSuspense();
  useGetCalendarConnections();

  useGetEmergencyAlerts({
    options: {
      enabled: isEmergencyAlertsEnabled,
    },
  });

  useEffect(() => {
    if (user && orgData) {
      OpenFeature.setContext({
        ...OpenFeature.getContext(),
        targetingKey: `${user.id}`,
        email: user.email,
        name: user.displayName,
        orgId: orgData.orgId,
      });
      googleAnalytics.identify(String(user.id));

      // NOTE: Initialize the widget for Beamer only when a user has been authenticated.
      // The beamer_config needs to have the property `lazy` in order to have the option of
      // initializing the widget programmatically.
      // docs: https://www.getbeamer.com/docs
      window.Beamer?.update({
        user_firstname: user.displayName,
        user_email: user.email,
        filter: user.role,
      });
      window.Beamer && window.Beamer.init();
    }
  }, [user, orgData]);

  return <>{children}</>;
};
