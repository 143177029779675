import { EmergencyAlertApiModel, EmergencyAlertApiRequest } from './apiModels';

class MockEmergencyAlerts {
  private data = new Map<number, EmergencyAlertApiModel>();
  private nextId = 0;

  constructor() {
    mockData.forEach(alert => {
      this.data.set(alert.id, alert);
    });
  }

  async post(emergencyAlert: EmergencyAlertApiRequest): Promise<EmergencyAlertApiModel> {
    const id = this.nextId++;
    const newEmergencyAlert: EmergencyAlertApiModel = {
      ...emergencyAlert,
      id,
      startedAt: new Date().toISOString(),
      source: 'airtame',
    };

    this.data.set(id, newEmergencyAlert);

    await sleep();

    return newEmergencyAlert;
  }

  async get(): Promise<EmergencyAlertApiModel[]> {
    await sleep();

    return [...this.data.values()];
  }

  async delete(id: number): Promise<void> {
    this.data.delete(id);

    await sleep();

    return;
  }

  async put(id: number, emergencyAlert: EmergencyAlertApiRequest): Promise<EmergencyAlertApiModel> {
    this.data.delete(id);

    return await this.post(emergencyAlert);
  }
}

function sleep() {
  const randomMs = Math.floor(Math.random() * 2000) + 1000; // Random between 1-3 seconds
  return new Promise(res => setTimeout(res, randomMs));
}

const mockData: EmergencyAlertApiModel[] = [
  {
    template: 'lockdown',
    headline: 'Campus Lockdown in Effect',
    description: 'Security threat reported near Building.',
    isDrill: false,
    startedByUser: {
      email: 'security.chief@organization.com',
    },
    startedAt: '2025-01-30T10:30:00Z',
    groups: [2, 19425],
    id: 9990,
    source: 'airtame',
  },
  {
    template: 'evacuate',
    headline: 'Fire Evacuation Notice',
    description:
      'Fire detected in Building B. Please evacuate immediately using nearest emergency exit.',
    isDrill: true,
    startedByUser: {
      email: 'facility.manager@organization.com',
      displayName: 'Facility Manager',
    },
    startedAt: '2025-01-30T09:15:00Z',
    groups: [5630, 19425],
    id: 9991,
    source: 'airtame',
  },
  {
    template: 'shelter',
    headline: 'Severe Weather Warning',
    description: 'Severe thunderstorm approaching. Seek shelter immediately.',
    isDrill: false,
    startedByUser: {
      email: 'emergency.response@organization.com',
    },
    startedAt: '2025-01-30T14:00:00Z',
    id: 9992,
    source: 'cap',
  },
  {
    template: 'hold',
    headline: 'Medical Emergency Hold',
    description: 'Medical emergency in progress on Floor 3. Please avoid the area.',
    isDrill: false,
    startedByUser: {
      email: 'medical.team@organization.com',
      displayName: 'Medical team',
    },
    startedAt: '2025-01-30T15:45:00Z',
    id: 9993,
    source: 'cap',
  },
  {
    template: 'all-clear',
    headline: 'All Clear - Resume Normal Operations',
    description: 'The previous emergency situation has been resolved. Resume normal operations.',
    isDrill: false,
    startedByUser: {
      email: 'security.ops@organization.com',
      displayName: 'Security Operations',
    },
    startedAt: '2025-01-30T13:00:00Z',
    expiresAt: '2025-01-30T13:05:00Z',
    id: 9994,
    source: 'airtame',
  },
];

const mockEmergencyAlerts = new MockEmergencyAlerts();
export { mockEmergencyAlerts };
