const ns = 'shared';

export const shared = {
  inlineTextInputField: (rootTestId = ''): string => `${ns}__${rootTestId}inline-text-input-field`,
  inlineTextInputAccept: (rootTestId = ''): string =>
    `${ns}__${rootTestId}inline-text-input-accept`,
  inlineTextInputCancel: (rootTestId = ''): string =>
    `${ns}__${rootTestId}inline-text-input-cancel`,
  selectInput: `${ns}__select-input`,
};
