import { DefinedUseQueryResult, QueryKey, useQuery } from '@tanstack/react-query';
import { getEmergencyAlertsError } from 'src/constants/errorMessages';
import { getEmergencyAlertsService } from 'src/services/emergencyAlerts/getEmergencyAlertsService';
import { EmergencyAlert } from 'src/typings/EmergencyAlerts';
import { UseQueryOptionsWithoutQueryKey } from 'src/typings/global';
import { APIError } from 'src/utils/error';
import { getEmergencyAlertsKey } from './utils';

type useGetEmergencyAlertsProps = {
  options?: UseQueryOptionsWithoutQueryKey<EmergencyAlert[], APIError>;
  customKey?: QueryKey;
};

export function useGetEmergencyAlerts(
  props: useGetEmergencyAlertsProps = {}
): DefinedUseQueryResult<EmergencyAlert[], APIError> {
  const { customKey } = props;

  return useQuery({
    queryKey: customKey || getEmergencyAlertsKey(),
    queryFn: () => getEmergencyAlertsService(),
    ...props?.options,
    meta: {
      errorMessage: getEmergencyAlertsError,
      ...props?.options?.meta,
    },
    initialData: [],
  });
}
