import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material';

const muiTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    fontFamily: 'var(--cloud-font-family)',
  },
  components: {
    MuiAutocomplete: {},
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
        root: {
          lineHeight: 'var(--input-base-line-height)',
          // overriding inline style
          padding: 'var(--input-base-padding) !important',
          borderRadius: 'var(--border-radius-200)',
          boxShadow: 'var(--form-field-box-shadow)',
          outline: 'none',
          height: 'var(--input-base-height)',
          fontSize: 'var(--input-base-font-size)',
          transition: 'none',
          backgroundColor: 'var(--form-field-background-color)',
          color: 'var(--form-field-input-color)',
          border: 'none',
          // overriding inline style
          width: '100% !important',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            borderColor: 'var(--form-field-focus-border-color)',
            boxShadow: 'var(--form-field-focus-box-shadow)',
          },
        },
      },
    },
  },
});

export const MuiThemeProvider = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  return <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>;
};
