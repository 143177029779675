import React from 'react';
import { BrowserTracing, init } from '@sentry/react';
import { createRoot } from 'react-dom/client';
import '@airtame/ui/dist/reset.css';
import '@airtame/ui/dist/airtame-ui-components.css';
import '@airtame/ui/dist/scrollbar.css';
import 'src/assets/scss/index.scss';
// NOTE: The order of the scss files and the components/Root is important since it affects
// the specificity in the CSS rules. Be mindful if you need to change
// this order since it will impact the UI of the application.
// eslint-disable-next-line import/order
import { Root } from 'src/components/routes/root';
import { ENVIRONMENT, SENTRY_DSN } from 'src/constants/parameters';
import './handleMissingDynamicImport';

function bootstrap() {
  if (ENVIRONMENT === 'prd' && SENTRY_DSN) {
    init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      release: __SENTRY_RELEASE_VERSION__,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  const container = document.getElementById('root')!;
  const root = createRoot(container);
  root.render(<Root />);
}

bootstrap();
